body {
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cool-shadow {
  filter: drop-shadow(0px 2px 4px rgba(52, 27, 91, 0.25));
}

.white-shadow {
  text-shadow: 0 1px 3px rgba(255, 255, 255, 0.7);
}

.black-shadow {
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.7);
}

.bw1 {
  border-width: .0625rem;
}

.cool-purple {
  color: #2d2962;
}

.bg-cool-purple {
  background-color: #2d2962;
}

.bg-mid-gray {
  background-color:#556;
}
.bg-gray {
  background-color:#778;
}
.bg-silver {
  background-color:#99a;
}
.bg-light-silver {
  background-color:#bbc;
}
.bg-moon-gray {
  background-color:#ccd;
}

.b--cool-border {
  border-color: #2d296233;
}
.b--cool-purple {
  border-color: #2d2962;
}
.w4 {
  width: 7rem;
}